import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="mailto:info@wezzperks.co.uk?subject=Enquiry" target="_blank">
          <span className="icon-logo"></span>
          <p>coming <span>soon</span></p>
        </a>
      </header>
    </div>
  );
}

export default App;
